.game-carousel-container{
    width: 100vw;
    background-color: black;
}
.game-poster{
    height: 300px;
    border: 3px solid rgba(64, 160, 62, 0.715);
    border-radius: 10px;
    overflow: hidden;
}
.game-poster > img{
    height: 100%;
    width: 100%;

}
.game-card-container{
    height: 550px;
    background-color: black;
}
.game-card{
    background-repeat: no-repeat;
    width:100%;
    height: 100%;
    background-size: cover;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)), var(--img);
}
.game-detail{
    position: absolute;
    top:200px;
    display: flex;
    justify-content: space-evenly;
    width:100%;
}
.game-title{
    color:white;
    display: flex;
    align-items: center;
}

.game-buttons-container{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:300px;
}

.game-review-button-container {
    display:flex;
    justify-content: space-between;
    align-items: center;
    color: green;
}

/* .btn-custom {
    display:flex;
    justify-content: space-between;
    align-items: center;
    color: green;

} */


.play-button-icon-container{
    padding:0px;
    margin:0px;
    width:150px;
}
.play-button-icon{
    padding:0px;
    margin:0px;
    color:rgb(64, 160, 62, 0.715);
    font-size: 3rem;
    transition: 0.3s;
    cursor: pointer;
}
.play-button-icon:hover{
    font-size: 4rem;
    color:rgb(255, 255, 255, 0.715);
}

@media only screen and (max-width: 800px) {
    .game-detail{
        flex-direction: column;
        align-items: center;
        top:20px;
    }
}
