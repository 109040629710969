@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

*{
  box-sizing: border-box;
  font-family: 'Roboto Serif', Montserrat, Helvetica, sans-serif;
}
body{
  padding:0;
  margin:0;
}
.App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black;
  color:white;
  margin-top:10px;
}

.game-header-button {
  padding:0px;
  margin: 1rem;;
  font-size: 1rem !important;
  transition: 0.3s !important;
  cursor: pointer !important;
  background-color: rgb(64, 160, 62, 0.815) !important;
  border: none !important;
}

.game-header-button:hover{
  font-size: 1rem !important;
  color:rgb(255, 255, 255, 0.815) !important;
}


.game-review-button {
  padding:0px;
  margin:0px;
  font-size: 1.5rem !important;
  transition: 0.3s !important;
  cursor: pointer !important;
  background-color: rgb(64, 160, 62, 0.815) !important;
  border: none !important;
}

.game-review-button:hover{
  font-size: 2rem !important;
  color:rgb(255, 255, 255, 0.815) !important;
}

